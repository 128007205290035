import type { JSX } from 'react';
import { CenteredButton as CenteredButtonComponent } from '@/components';
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';

export type CenteredButtonProps =
  SliceComponentProps<Content.CenteredButtonSlice>;

const CenteredButton = ({ slice }: CenteredButtonProps): JSX.Element => (
  <CenteredButtonComponent {...slice} />
);

export default CenteredButton;
